<script>
import Ring from '../components/home/ring.vue'
import Algo from '../components/home/algo.vue'
import HomeDashboardMetrics from '@shell/components/HomedashboardMetrics';
import { parseSi } from '@shell/utils/units';
import { MANAGEMENT } from '@shell/config/types';

export default {
  name:       'Home',
  layout:     'home',
  components:{
    Ring,Algo,HomeDashboardMetrics
  },
  data(){
    return {
      currentCluster:null
    }
  },
  computed:{
    graphHeight(){
      return '120px'
    },
    availableCpu() {
      const reserved = parseSi(this.currentCluster?.status?.requested?.cpu);
      const allocatable = parseSi(this.currentCluster?.status?.allocatable?.cpu);
      if ( allocatable > 0 && reserved >= 0 ) {
        return Math.max(0, allocatable - reserved);
      } else {
        return null;
      }
    }
  },
  mounted(){
     this.$store.dispatch('management/findAll',  { type: MANAGEMENT.NODE }).then(res=>{
        console.log(res)
        this.currentCluster = res[0]
     })
  },
  methods:{
    cpuUsed(cluster) {
      return parseSi('6441869655n');
    },
    cpuTotal(cluster) {
      return parseSi(cluster?.status?.capacity?.cpu);
    },
  }
};

</script>

<template>
  <div class="home-page">
    <div class="leftpanel">
      <div class="top">
        <div class="title">数据管理</div>
        <div class="indexcontainer">
          <div class="indexbox">
            <div class="index">15 <img src="@/shell/assets/images/home/icon1.png" alt="icon1"></div>
            <div class="desc">算法模型数量</div>
          </div>
          <div class="indexbox">
            <div class="index">15  <img src="@/shell/assets/images/home/icon2.png" alt="icon2"></div>
            <div class="desc">总的请求量</div>
          </div>
          <div class="indexbox">
            <div class="index">300  <img src="@/shell/assets/images/home/icon3.png" alt="icon3"></div>
            <div class="desc">算法模型数量</div>
          </div>
          <div class="indexbox">
            <div class="index">15  <img src="@/shell/assets/images/home/icon4.png" alt="icon4"></div>
            <div class="desc">错误请求量</div>
          </div>
          <div class="indexbox">
            <div class="index">15  <img src="@/shell/assets/images/home/icon5.png" alt="icon5"></div>
            <div class="desc">系统告警数量</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="monitor">
          <div class="title">算法模型监控</div>
          <div class="algolist">
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
            <Algo/>
          </div>
        </div>
        <div class="cards">
          <div class="card">
            <div class="title">算法模型的调用趋势</div>
          </div>
          <div class="card">
            <div class="title">错误率</div>
          </div>
          <div class="card">
            <div class="title">平均响应时间</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightpanel">
      <div class="top">
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type:'cpu',
                color:'#0163FF',
                rate:.75
              }"/>
            </div>
            <div class="right">
              {{(cpuUsed()/ cpuTotal(currentCluster)* 100).toFixed(2)}}%
              <span>CPU</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/Qcw1smVIz/skj_home-cpu?orgId=1'"
              :graph-height="graphHeight"
            />
          </div>
        </div>
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type:'memory',
                color:'#25CB4A',
                rate:.5
              }"/>
            </div>
            <div class="right">
              75%
              <span>内存</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/AiOVxFSIk/skj_home-memory?orgId=1'"
              :graph-height="graphHeight"
            />
          </div>
        </div>
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type:'disk',
                color:'#FC5849',
                rate:.3
              }"/>
            </div>
            <div class="right">
              75%
              <span>磁盘</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/OgAGJKISk/skj_home-disk?orgId=1'"
              :graph-height="graphHeight"
            />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="mainstatus">
          <div class="title">AI中台概况</div>
          <div class="overview">
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview1.png" alt="overview1">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">算法模型</span>
                <span>算法模型的详情...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                 i
                </div>
              </div>
            </div>
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview2.png" alt="overview2">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">数据处理流程</span>
                <span>算法模型的详情...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                 i
                </div>
              </div>
            </div>
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview3.png" alt="overview3">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">API文档</span>
                <span>算法模型的详情...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                 i
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logs">
          <div class="title">日志</div>
          <div class="loginput">
            <input type="text">
            <img src="@/shell/assets/images/home/search.png" alt="search">
          </div>
          <div class="loglist">
            <div class="log">1.算法模型名称统一导出</div>
            <div class="log">2.算法模型名称统一导出</div>
            <div class="log">3.算法模型名称统一导出</div>
            <div class="log">4.算法模型名称统一导出</div>
            <div class="log">5.算法模型名称统一导出</div>
            <div class="log">6.算法模型名称统一导出</div>
            <div class="log">7.算法模型名称统一导出</div>
            <div class="log">8.算法模型名称统一导出</div>
            <div class="log">9.算法模型名称统一导出</div>
            <div class="log">10.算法模型名称统一导出</div>
            <div class="log">11.算法模型名称统一导出</div>
            <div class="log">12.算法模型名称统一导出</div>
            <div class="log">13.算法模型名称统一导出</div>
            <div class="log">14.算法模型名称统一导出</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.home-page {
  background-color: #F7F8FA;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap:20px;
  height: 100%;
  overflow: hidden;

  .leftpanel {
    flex:840;
    display: flex;
    flex-direction: column;
    gap:20px;
    .top {
      flex: 186;
      width:100%;
      background:#ffffff;
      border-radius: 10px;
      padding: 30px 40px 30px 40px;
      .title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(51, 51, 51, 1);
      }
      .indexcontainer {
        margin-top: 21px;
        display: flex;
        flex-direction: row;
        gap:15px;
        height: calc(100% - 50px);
        .indexbox{
          flex: 1;
          border-radius:10px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .index {
            font-size: 24px;
            font-family: 'Microsoft Yahei';
            font-weight: 700;
            letter-spacing: 0px;
            color: rgba(51, 51, 51, 1);
            img {
              width: 26px;
              float: right;
            }
          }
          .desc {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(51, 51, 51, 1);
            text-align: left;
          }
        }
        .indexbox:nth-of-type(1){
          background: rgba(255, 226, 229, 1);
        }
        .indexbox:nth-of-type(2){
          background: rgba(255, 244, 222, 1);
        }
        .indexbox:nth-of-type(3){
          background: rgba(220, 252, 231, 1);
        }
        .indexbox:nth-of-type(4){
          background: rgba(243, 232, 255, 1);
        }
        .indexbox:nth-of-type(5){
          background: rgba(232, 239, 255, 1);
        }
      }
    }
    .bottom {
      flex: 744;
      display: flex;
      flex-direction: row;
      gap:20px;
      .monitor {
        flex:383;
        background:#ffffff;
        border-radius: 10px;
        padding: 30px 10px 30px 0px;
        position: relative;
        .title {
          font-size: 20px;
          font-weight: 500;
          height: 20px;
          letter-spacing: 0px;
          padding-left: 26px;
          color: rgba(51, 51, 51, 1);
        }
        .algolist {
          overflow: scroll;
          position: absolute;
          left:10px;
          top: 80px;
          width:calc(100% - 20px);
          height: -webkit-fill-available;
          padding-left: 20px;
          padding-right: 20px;
          .log {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(51, 51, 51, 1);
            height:56px;
            padding-top:16px;
            padding-bottom:16px;
            padding-left:20px;
            padding-right:20px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
          }
          &::-webkit-scrollbar{
            width:3px !important;
          }
          &::-webkit-scrollbar-thumb{
            background: rgba(238, 238, 238, 1)  !important;
            border-radius:2.5px  !important;
          }
        }
      }
      .cards {
        flex:437;
        display: flex;
        flex-direction: column;
        gap:20px;
        .card{
          width: 100%;
          height: 100%;
          background:#ffffff;
          border-radius: 10px;
          padding: 30px 40px 30px 40px;
          .title {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
  .rightpanel {
    flex:764;
    display: flex;
    flex-direction: column;
    gap:20px;
    overflow: hidden;
    .top {
      background:#ffffff;
      flex: 499;
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .graph {
        flex: 1;
        padding: 30px 54px;
        display: flex;
        flex-direction: row;
        .ring {
          display: flex;
          flex:238;
          flex-direction: row;
          gap:33px;
          .left {
            svg {
              width: 102px;
              height: 102px;
            }
          }
          .right{
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(51, 51, 51, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            span {
              font-size: 24px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              color: var(--lighterText);
            }
          }
        }
        .rightgraph {
          flex:375;
        }
      }
    }
    .bottom {
      flex: 431;
      width: 100%;
      display: flex;
      gap: 20px;
      .mainstatus {
        height: 100%;
        width: calc((100% - 20px)/2);
        background:#ffffff;
        border-radius: 10px;
        padding: 30px 14px 30px 14px;
        .title {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0px;
          height: 20px;
          margin-bottom: 10px;
          padding-left: 26px;
          color: rgba(51, 51, 51, 1);
        }
        .overview {
          display: flex;
          flex-direction: column;
          height: 100%;
         .overviewitem{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          padding-left: 26px;
          padding-right: 26px;
          .overviewpic{
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .overviewdesc{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap:14px;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            color: var(--lighterText);
            .desctitle {
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0px;
              color: rgba(51, 51, 51, 1);
            }
           
          }  
          .overviewindex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap:14px;
            justify-content: center;
            .fakenum {
              width: 17px;
              height: 17px;
              background-color: transparent;
            }
            .num {
              width: 17px;
              height: 17px;
              line-height: 17px;
              text-align: center;
              color: #ffffff;
              border-radius: 50%;
              opacity: 1;
              background: rgba(23, 124, 255, 1);
              cursor: pointer;
            }
          }
         } 
         .overviewitem:nth-of-type(2){
            border-top: 1px dashed rgba(233, 233, 233, 1);
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
          }
        }
      }
      .logs {
        height: 100%;
        width: calc((100% - 20px)/2);
        position: relative;
        background:#ffffff;
        border-radius: 10px;
        padding: 30px 10px 0px 10px;
        .title {
          font-size: 20px;
          font-weight: 500;
          height: 20px;
          margin-bottom: 24px;
          letter-spacing: 0px;
          padding-left:20px;
          color: rgba(51, 51, 51, 1);
        }
        .loginput {
          position: relative;
          padding-left:20px;
          padding-right:20px;
          input{
            border-radius: 10px;
            background: #eeeeee;
            width: 100%;
            height: 28px;
            outline: none;
            border:transparent
          }
          img {
            width:14px;
            height: 14px;
            position: absolute;
            right: 34px;
            top: 7px;
          }
        }
        .loglist {
          overflow: scroll;
          position: absolute;
          left:10px;
          top: 102px;
          width:calc(100% - 20px);
          height: -webkit-fill-available;
          .log {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(51, 51, 51, 1);
            height:56px;
            padding-top:16px;
            padding-bottom:16px;
            padding-left:20px;
            padding-right:20px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
          }
          &::-webkit-scrollbar{
            width:3px !important;
          }
          &::-webkit-scrollbar-thumb{
            background: rgba(238, 238, 238, 1)  !important;
            border-radius:2.5px  !important;
          }
        }
      }
    }
  }
}

</style>
<style lang="scss">
.home-page {
  .search {
    align-items: center;
    display: flex;

    > INPUT {
      background-color: transparent;
      height: 30px;
      padding: 8px;
    }
  }

  h2 {
    font-size: 16px;
  }
}
</style>


